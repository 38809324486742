import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Box,
  Divider,
} from "@mui/material";
import logo from "../assets/logo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../App.css";
import { Call, Schedule } from "@mui/icons-material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import axios from "axios";

function LambertNemec() {
  const [companyName, setCompanyName] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("ca");
  const [callType, setCallType] = useState("ad_call_british_female"); // State for phone call type

  const handleSubmit = async (event) => {
    event.preventDefault();
    let url = "";

    const fromNumber = "14313059330";
    const baseUrl =
      "https://initiateretellcall-655075094783.us-central1.run.app";

    switch (callType) {
      case "ad_call_british_female":
        url = `${baseUrl}?agent_id=agent_0bbf2d23c8bee720b9d1fec6dd&company_name=${companyName}&to_number=${phoneNumber}&from_number=${fromNumber}`;
        break;
      case "ad_call_british_male":
        url = `${baseUrl}?agent_id=agent_6e4d85e085506ebcee1ae83485&company_name=${companyName}&to_number=${phoneNumber}&from_number=${fromNumber}`;
        break;
      case "ad_call_american_female":
        url = `${baseUrl}?agent_id=agent_c553bd9c7750c173775d302a4d&company_name=${companyName}&to_number=${phoneNumber}&from_number=${fromNumber}`;
        break;
      case "ad_call_south_african_female":
        url = `${baseUrl}?agent_id=agent_4588c3304d8599d10f716a1041&company_name=${companyName}&to_number=${phoneNumber}&from_number=${fromNumber}`;
        break;
      case "reference_call_british_female":
        url = `${baseUrl}?agent_id=agent_e44dfc30d1bd096c8930464818&candidate_name=${candidateName}&company_name=${companyName}&to_number=${phoneNumber}&from_number=${fromNumber}`;
        break;
      case "reference_call_british_male":
        url = `${baseUrl}?agent_id=agent_93ec334bc5d5f1a0471cbd0f27&candidate_name=${candidateName}&company_name=${companyName}&to_number=${phoneNumber}&from_number=${fromNumber}`;
        break;
      case "reference_call_american_female":
        url = `${baseUrl}?agent_id=agent_8c796318735a834a18a278d426&candidate_name=${candidateName}&company_name=${companyName}&to_number=${phoneNumber}&from_number=${fromNumber}`;
        break;
      case "reference_call_south_african_female":
        url = `${baseUrl}?agent_id=agent_056160e424a3a24f92a2aa68e3&candidate_name=${candidateName}&company_name=${companyName}&to_number=${phoneNumber}&from_number=${fromNumber}`;
        break;
      default:
        break;
    }

    await axios
      .request(url, {
        method: "POST",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        if (res.status === 200) {
          alert("Call initiated successfully!");
        } else {
          alert("Failed to initiate the call.");
        }
      });
  };

  return (
    <div className="full-page-background">
      <Grid item xs={12} md={7}>
        <Container className="App" maxWidth="sm">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Lambert Nemec Phone Call DEMOs</h1>
          <h3>
            Testing website for Lambert Nemec's Phone Call DEMOs with
            JasperVOCAL
          </h3>
          <form onSubmit={handleSubmit}>
            <Select
              value={callType}
              onChange={(e) => setCallType(e.target.value)}
              required
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
              }}
            >
              <MenuItem value="ad_call_british_female">
                Ad Call (british female)
              </MenuItem>
              <MenuItem value="ad_call_british_male">
                Ad Call (british male)
              </MenuItem>
              <MenuItem value="ad_call_american_female">
                Ad Call (american female)
              </MenuItem>
              <MenuItem value="ad_call_south_african_female">
                Ad Call (south african female)
              </MenuItem>
              <MenuItem value="reference_call_british_female">
                Reference Call (british female)
              </MenuItem>
              <MenuItem value="reference_call_british_male">
                Reference Call (british male)
              </MenuItem>
              <MenuItem value="reference_call_american_female">
                Reference Call (american female)
              </MenuItem>
              <MenuItem value="reference_call_south_african_female">
                Reference Call (south african female)
              </MenuItem>
            </Select>
            {(callType === "ad_call_british_female" ||
              callType === "ad_call_british_male" ||
              callType === "ad_call_american_female" ||
              callType === "ad_call_south_african_female") && (
              <div>
                <TextField
                  label="Company name that listed job posting"
                  variant="filled"
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
            )}
            {(callType === "reference_call_british_female" ||
              callType === "reference_call_british_male" ||
              callType === "reference_call_american_female" ||
              callType === "reference_call_south_african_female") && (
              <div>
                <TextField
                  label="Candidate name"
                  variant="filled"
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setCandidateName(e.target.value)}
                  required
                />
                <TextField
                  label="Company name"
                  variant="filled"
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  fullWidth
                  margin="normal"
                  onChange={(e) => setCompanyName(e.target.value)}
                  required
                />
              </div>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "4px",
                    }}
                    required
                  >
                    <MenuItem value="ca">🇨🇦 (+1)</MenuItem>
                    <MenuItem value="us">🇺🇸 (+1)</MenuItem>
                    <MenuItem value="gb">🇬🇧 (+44)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box width={"100%"}>
                  <br />
                  <PhoneInput
                    country={country}
                    value={phoneNumber}
                    disableDropdown={true}
                    disableCountryGuess={true}
                    onChange={setPhoneNumber}
                    enableLongNumbers={true}
                    inputStyle={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      height: "56px",
                      borderRadius: "4px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </Box>
              </Grid>
            </Grid>
            <br />
            <h3>
              After clicking to receive the DEMO call, you will receive a call
              from the following phone number: <b>+1 (431) 815-9527</b>
            </h3>
            <br />
            <Button
              className="callBtn"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              <h2
                style={{
                  fontSize: "16px",
                }}
              >
                Click here to receive the DEMO call
              </h2>
              <div style={{ width: "20px" }} />
              <Call className="callIcon" />
            </Button>
          </form>
        </Container>
      </Grid>
    </div>
  );
}

export default LambertNemec;

import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import logo from "../../assets/logo.png";
import { Call } from "@mui/icons-material";

export const MedicareInsuranceDEMO = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [leadState, setLeadState] = useState("");
  const [country, setCountry] = useState("us");
  const [country2, setCountry2] = useState("us");

  const [companyName, setCompanyName] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [callType, setCallType] = useState("ad_call_british_female"); // State for phone call type

  const [testV2DropdownOption, setTestV2DropdownOption] = useState(
    "medicare_supp_cc_lt"
  );
  const [testV2FirstName, setTestV2FirstName] = useState("");
  const [testV2State, setTestV2State] = useState("");
  const [testV2FacebookPageName, setTestV2FacebookPageName] = useState("");
  const [testV2PhoneNumber, setTestV2PhoneNumber] = useState("");
  const [testV2LiveTransferPhoneNumber, setTestV2LiveTransferPhoneNumber] =
    useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const human_agent_first_name = "Marco";

    // const fromNumber = "12392081459";
    const fromNumber = "14313059330";
    // const fromNumber = "15489185087";
    const baseUrl =
      "https://initiateretellcall-655075094783.us-central1.run.app";
    let human_agent_id = "0ho90QkFDmsLuKGZ4iAv";
    let lead_id = "FSHpnvJoxO7iFKP63BxF";
    let agent_id = "agent_01b2663eaef5ae0af96f34cf22";

    let lead_first_name = testV2FirstName;
    let lead_state = testV2State;
    let human_agent_company_name =
      testV2FacebookPageName == "" ? "Senior Services" : testV2FacebookPageName;
    let human_agent_number = `+${testV2LiveTransferPhoneNumber}`;
    let lead_type = testV2DropdownOption;
    let lead_tz = "America/Chicago";
    let human_agent_tz = "America/New_York";
    let to_number = testV2PhoneNumber;

    await axios
      .request(
        `${baseUrl}?lead_id=${lead_id}&lead_first_name=${lead_first_name}&from_number=${fromNumber}&agent_id=${agent_id}&human_agent_id=${human_agent_id}&human_agent_company_name=${human_agent_company_name}&to_number=+${to_number}&lead_state=${lead_state}&human_agent_number=${human_agent_number}&human_agent_first_name=${human_agent_first_name}&lead_type=${lead_type}&human_agent_tz=${human_agent_tz}&lead_tz=${lead_tz}`,
        {
          method: "GET",
          headers: {
            "ngrok-skip-browser-warning": "69420",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => window.alert("Call initiated successfully!"));
  };

  return (
    <div className="full-page-background">
      <Grid item xs={12} md={7}>
        <Container className="App" maxWidth="sm">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Insurance Calling Scripts DEMO</h1>
          <h3>Testing website for JasperVOCAL's Insurance Calling Scripts</h3>
          <form onSubmit={handleSubmit}>
            <Select
              value={testV2DropdownOption}
              onChange={(e) => {
                setTestV2DropdownOption(e.target.value);
              }}
              required
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
              }}
            >
              <MenuItem value="medicare_supp_cc_lt">
                Medicare Artificial Voice Leads
              </MenuItem>
              <MenuItem value="medicare_supp_fb_lt">
                Medicare Facebook Leads Live Transfer
              </MenuItem>
              <MenuItem value="medicare_supp_t65_fb_lt">
                Medicare T65 Facebook Leads Live Transfer
              </MenuItem>
              <MenuItem value="mapd_fb_lt">
                MAPD Facebook Leads Live Transfer
              </MenuItem>
              {/* <MenuItem value="mapd_fb_appointments">
                MAPD Facebook Leads Appointments
              </MenuItem> */}
              <MenuItem value="final_expense_fb_lt">
                Final Expense Facebook Leads Live Transfers
              </MenuItem>
              {/* <MenuItem value="final_expense_fb_appointments">
                Final Expense Facebook Leads Appointments
              </MenuItem> */}
              <MenuItem value="final_expense_cc_lt">
                Final Expense Artificial Voice Leads
              </MenuItem>
              <MenuItem value="affordable_care_act_cc_lt">
                ACA Artificial Voice Leads
              </MenuItem>
            </Select>
            {testV2DropdownOption == "medicare_supp_cc_lt" ||
            testV2DropdownOption == "final_expense_cc_lt" ||
            testV2DropdownOption == "affordable_care_act_cc_lt" ? (
              <>
                <TextField
                  autoFocus
                  margin="dense"
                  label="First Name"
                  type="text"
                  fullWidth
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  value={testV2FirstName}
                  onChange={(e) => setTestV2FirstName(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="State"
                  type="text"
                  fullWidth
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  value={testV2State}
                  onChange={(e) => setTestV2State(e.target.value)}
                />
              </>
            ) : (
              <>
                <TextField
                  autoFocus
                  margin="dense"
                  label="First Name"
                  type="text"
                  fullWidth
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  value={testV2FirstName}
                  onChange={(e) => setTestV2FirstName(e.target.value)}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  label="Facebook Page Name"
                  type="text"
                  fullWidth
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  value={testV2FacebookPageName}
                  onChange={(e) => setTestV2FacebookPageName(e.target.value)}
                />
                <TextField
                  margin="dense"
                  label="State"
                  type="text"
                  fullWidth
                  sx={{
                    color: "white",
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  value={testV2State}
                  onChange={(e) => setTestV2State(e.target.value)}
                />
              </>
            )}

            <p>
              <b>Live transfer phone number</b>
            </p>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={country2}
                    onChange={(e) => setCountry2(e.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "4px",
                    }}
                    required
                  >
                    <MenuItem value="ca">🇨🇦 (+1)</MenuItem>
                    <MenuItem value="us">🇺🇸 (+1)</MenuItem>
                    <MenuItem value="gb">🇬🇧 (+44)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box width={"100%"}>
                  <br />
                  <PhoneInput
                    country={country2}
                    value={testV2LiveTransferPhoneNumber}
                    disableDropdown={true}
                    disableCountryGuess={true}
                    onChange={setTestV2LiveTransferPhoneNumber}
                    enableLongNumbers={true}
                    inputStyle={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      height: "56px",
                      borderRadius: "4px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </Box>
              </Grid>
            </Grid>

            <p>
              <b>Your phone number</b>
            </p>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "4px",
                    }}
                    required
                  >
                    <MenuItem value="ca">🇨🇦 (+1)</MenuItem>
                    <MenuItem value="us">🇺🇸 (+1)</MenuItem>
                    <MenuItem value="gb">🇬🇧 (+44)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box width={"100%"}>
                  <br />
                  <PhoneInput
                    country={country}
                    value={testV2PhoneNumber}
                    disableDropdown={true}
                    disableCountryGuess={true}
                    onChange={setTestV2PhoneNumber}
                    enableLongNumbers={true}
                    inputStyle={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      height: "56px",
                      borderRadius: "4px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </Box>
              </Grid>
            </Grid>
            <br />
            <h3>
              After clicking to receive the DEMO call, you will receive a call
              from the following phone number: <b>+1 (431) 305-9330</b>
            </h3>
            <br />
            <Button
              className="callBtn"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              <h2
                style={{
                  fontSize: "16px",
                }}
              >
                Click here to receive the DEMO call
              </h2>
              <div style={{ width: "20px" }} />
              <Call className="callIcon" />
            </Button>
          </form>
        </Container>
      </Grid>
    </div>
  );
};

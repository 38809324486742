import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import logo from "../../assets/logo.png";
import { Call } from "@mui/icons-material";

export const AgentAutopilotDEMOEspanol = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [country, setCountry] = useState("es");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const fromNumber = "15489185087";
    const contactID = "w28DWvRitkfb7XrM5op3";
    const agentID = "agent_57d0c9df1ed184e6dca51cc4d8";
    const baseUrl =
      "https://initiateretellcall-655075094783.us-central1.run.app";

    const url = `${baseUrl}?user_first_name=${userFirstName}&to_number=${phoneNumber}&from_number=${fromNumber}&agent_id=${agentID}`;

    console.log(`Starting call with URL ${url}`);

    await axios
      .request(url, {
        method: "GET",
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => console.log(res));
  };

  return (
    <div className="full-page-background">
      <Grid item xs={12} md={7}>
        <Container className="App" maxWidth="sm">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>Agent Autopilot DEMO Español</h1>
          <h3>DEMO creada por JasperVOCAL como ejemplo de Agent Autopilot</h3>
          <form onSubmit={handleSubmit}>
            <>
              <TextField
                label="Nombre"
                variant="filled"
                sx={{
                  color: "white",
                  backgroundColor: "white",
                  borderRadius: "4px",
                }}
                fullWidth
                margin="normal"
                value={userFirstName}
                onChange={(e) => setUserFirstName(e.target.value)}
                required
              />
            </>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal">
                  <Select
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "4px",
                    }}
                    required
                  >
                    <MenuItem value="es">🇪🇸 (+34)</MenuItem>
                    <MenuItem value="us">🇺🇸 (+1)</MenuItem>
                    <MenuItem value="gb">🇬🇧 (+44)</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box width={"100%"}>
                  <br />
                  <PhoneInput
                    country={country}
                    value={phoneNumber}
                    disableDropdown={true}
                    disableCountryGuess={true}
                    onChange={setPhoneNumber}
                    enableLongNumbers={true}
                    inputStyle={{
                      width: "100%",
                      textAlign: "center",
                      fontSize: "16px",
                      height: "56px",
                      borderRadius: "4px",
                      border: "1px solid #ced4da",
                    }}
                    required
                  />
                </Box>
              </Grid>
            </Grid>
            <h3>
              Después de hacer clic para recibir la llamada DEMO, recibirá una
              llamada desde el siguiente número de teléfono:{" "}
              <b>+1 (548) 918-5087</b>
            </h3>
            <br />
            <Button
              className="callBtn"
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              <h2
                style={{
                  fontSize: "16px",
                }}
              >
                Haga clic aquí para recibir la llamada DEMO
              </h2>
              <div style={{ width: "20px" }} />
              <Call className="callIcon" />
            </Button>
          </form>
        </Container>
      </Grid>
    </div>
  );
};
